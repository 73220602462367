(function($){
	$(function() {

		//place here your code
		$('footer').stickyFooter();

		var snapper = new Snap({
			element: document.getElementById('content')
		});

		$('#open-left').click(function(){
			snapper.open('right');
		});

		$('.image-link').magnificPopup({type:'image'});

		$('.parent-container').magnificPopup({
			delegate: 'a', // child items selector, by clicking on it popup will open
			type: 'image',
			gallery:{
				enabled:true
			}
			// other options
		});
	});
})(jQuery);
